import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
// import Login from './components/auth/Login';
// import Menu from './components/main/Menu';
// import Home from './components/main/Home';
// import Paso1 from './Paso1';
// import CardHome from './components/ui/CardHome';
class App extends Component {
  render() {
    return (
      <div>
          {/* <Login></Login> */}
      </div>
      
    )
  }
}
/*<h1 className="text-3xl font-bold underline">
      Hello world!
    </h1> */
export default App;
