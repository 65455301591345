import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { startChecking } from '../actions/auth';
import { Login } from '../components/auth/Login';
import { DashboardRoutes } from './DashboardRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import LoaderSplash from '../components/ui/LoaderSplash';

import useDebounce from '../hooks/useDebounce';
import { useRef } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import useUpdateEffect from '../hooks/useUpdateEffect';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, id } = useSelector((state) => state.auth);
  const [check, setCheck] = useState(checking);

  // useUpdateEffect(() => alert(check), [check]);

  useEffect(() => {
    dispatch(startChecking());
    setTimeout(() => setCheck(checking), 4800);
  }, [checking, dispatch]);

  if (check) {
    return <LoaderSplash />;
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/login"
            component={Login}
            isAuthenticated={!!id}
          />
          <PrivateRoute
            path="/"
            component={DashboardRoutes}
            isAuthenticated={!!id}
          />
        </Switch>
      </div>
    </Router>
  );
};
