import React from 'react';
import Logo from './img/LogoAlumdata.svg';
import '../Autosetup.css';

const FooterSetup = () => {
    return (
        <div className="footerSetup w-full m-auto py-5 text-center font-semibold text-xl">
            <div className="helpAutosetup">?</div>
            <img className="logoFooterAutosetup" src={Logo} width="25%"/>
        </div>
    )
}

export default FooterSetup; 