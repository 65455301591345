import React, { Component } from "react";
import "../../App";
import "../../App.css";

const ProgressSetupBar = () => {
    return (
        <div className="containerPasos container mx-auto w-4/5 h-1/4 pb-4 relative mt-12 flex flex-row flex-wrap justify-between">
            <div className="numeroPaso px-2 py-3 m-2 rounded-full bg-progress-purple-color text-white relative left text-white my-auto">
                <span className="m-3 text-xl text-center">1</span><br />
                <div className="labelPaso text-normalPurple text-sm -mx-2"> Identidad </div>
            </div>
            <div className="numeroPaso px-2 py-3 m-2 rounded-full bg-progress-gray-color text-darkGray relative left text-white my-auto">
                <span className="m-3 text-xl text-center">2</span><br />
                <div className="labelPaso text-darkGray text-sm -mx-2"> Usuarios </div>
            </div>
            <div className="numeroPaso px-2 py-3 m-2 rounded-full bg-progress-gray-color text-darkGray relative left text-white my-auto">
                <span className="m-3 text-xl text-center">3</span><br />
                <div className="labelPaso text-darkGray text-sm -mx-6"> Implementación </div>
            </div>
            <div className="numeroPaso px-2 py-3 m-2 rounded-full bg-progress-gray-color text-darkGray relative left text-white my-auto">
                <span className="m-3 text-xl text-center">4</span><br />
                <div className="labelPaso text-darkGray text-sm ml-1"> Metas </div>
            </div>
            <div className="absolute px-8 w-full">
                <div className="bg-progress-purple-color py-1 my-5 relative float-left w-1/3 -z-10"></div>
                <div className="bg-progress-gray-color py-1 my-5 relative float-left w-1/3 -z-10"></div>
                <div className="bg-progress-gray-color py-1 my-5 relative float-left w-1/3 -z-10"></div>
            </div>
      </div>
    );
};

export default ProgressSetupBar; 

