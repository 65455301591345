import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loaderSplash from '../../css/animations/loaderSplash.json'

class LoaderSplash extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loaderSplash,
            renderer: 'svg',
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
               <Lottie
                options={defaultOptions}
                style={{ width: '70%' }}
               />
        )
    };  
}
export default LoaderSplash;