import React, {useState, useCallback, useEffect} from 'react';
import TitleBar from './ui/TitleBar';
import FooterSetup from './ui/FooterSetup';
import Background from './ui/img/geometry-bg.png';
import Rocket from './ui/img/Saly-Rocket.png';
import StepIllustration from './ui/img/Step01_Illustration.png';
import cargarImagen from './ui/img/loadImg.svg';
import ProgressSetupBar from './ui/ProgressSetupBar';
import { useDropzone } from 'react-dropzone';
import './Autosetup.css'

const FirstStep = () => {

  const [nombreImagen, setNombreImagen] = useState('');

  const [images, setImages] = useState([]);
  const [vprevia, setVprevia] = useState(cargarImagen);

  const onDrop = useCallback((acceptedFiles, rejectFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.size < 1400000) {
        const reader = new FileReader();
        reader.onload = () => {
          setImages((prevState) => [...prevState, reader.result]);
        };
        setNombreImagen(file.name);
        reader.readAsDataURL(file);
      } else {
        alert('El peso de la imagen no debe exceder los 2MB');
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  useEffect(() => {
    const last = images.length;
    if (images.length > 0) {
      setVprevia(images[last - 1]);
    } else {
      setVprevia(cargarImagen);
    }
  }, [images]);

    return (
        <div className="w-full flex flex-row">
            <TitleBar />
            <ProgressSetupBar />
            <div className="w-1/2 mx-auto flex">
                <div className="w-1/2 mt-8 px-1">
                    {/*Logotipo principal*/}
                    <span className="text-basic-gray p-1 px-2 text-sm"><strong className="text-smartPurple">*</strong>Logotipo principal</span>
                    <div {...getRootProps()} 
                        className="w-full bg-white text-center border-dashed border-2 mt-1 rounded-xl py-5">
                        <img className="w-1/2 inline" src={vprevia} />
                        <div className="block">
                            <input {...getInputProps()} />
                            <span className="text-smartPurple text-lg font-bold block">
                                Arrastre su archivo
                            </span>
                            <span className="text-smartPurple text-sm block my-2">
                                o elegir desde su dispositivo
                            </span>
                            <span className="text-basic-gray text-xs block font-thin px-1">
                                Sólo archivos SVG, PNG o GIF de máximo 2 MB
                            </span>
                        </div>
                    </div>
                    {/*Logotipo secundario*/}
                    <span className="text-basic-gray p-1 px-2 text-sm relative top-6">Icono o logotipo secundario (opcional)</span>
                    <div {...getRootProps()} 
                        className="w-full bg-white text-center border-dashed border-2 mt-8 rounded-xl py-4">
                        <div className="block">
                            <input {...getInputProps()} />
                            <span className="text-smartPurple text-sm font-medium block my-1">
                                Elegir desde su dispositivo
                            </span>
                            <span className="text-basic-gray text-xs block font-thin px-1">
                                Sólo archivos SVG, PNG o GIF de máximo 2 MB
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img className="rocketImg" src={Rocket} width="30%"/>
                <img className="backgroundSteps z-0" src={Background} width="28%"/>  
            </div>
            <img className="AutosetupStep01" src={StepIllustration} width="13%" />
            <FooterSetup />
        </div>
    );
};

export default FirstStep;