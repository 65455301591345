import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loaderButton from '../../css/animations/loaderButton.json'

class LoaderAnimation extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loaderButton,
            renderer: 'svg'
        }
        return (
            <div>
               <Lottie
                options={defaultOptions}
                height={50}
                width={50}
               />
            </div>
        )
    };  
}
export default LoaderAnimation;