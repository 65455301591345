import React from 'react';
import PropTypes from 'prop-types';
import LoaderAnimation from './LoaderAnimation';

const themes = {
  basic:
    'font-sans text-lg appearance-none relative block w-1/2 px-12 my-6 py-0 border border-transparent text-white bg-cyan-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-borders-gray sm:text-base',
  basicB:
    'font-sans text-lg appearance-none relative block w-full px-12  py-0 border border-transparent text-white bg-cyan-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-borders-gray sm:text-base',
  small:
    'font-sans text-lg appearance-none my-4 mx-2 px-12 py-2 border-transparent text-white bg-light-gray rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-borders-gray sm:text-base',
};

const SmartLoadButton = ({
  title,
  type = 'submit',
  style,
  className,
  theme = 'basic',
  onClick = () => {},
  children,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      style={style}
      className={`${themes[theme]} loadButton ${className}`}
    >
      {title}
      {children}
      <LoaderAnimation />
    </button>
  );
};

SmartLoadButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']),
  theme: PropTypes.oneOf(Object.keys(themes)),
  onClick: PropTypes.func,
};

export default SmartLoadButton;
