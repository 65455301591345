import React from 'react';
import { useSelector } from 'react-redux';

const UserSetup = () => {

    const { userImg, name } = useSelector((state) => {
        return state.auth;
    });

    return(
        <div className="-mt-2 absolute left-8 font-light w-1/3">
            <img
                className="w-12 relative float-left mr-2 rounded-full"
                src={userImg}
                alt="aspirantes"
            />
            <span className="text-white relative float-left ml-2 text-left text-sm mt-0 pr-6 pb-1 pl-1 w-1/2 spanNameHeader">
                {name}
            </span>
            <br />
            <span className="text-white relative float-left ml-2 text-left text-xs -mt-15 pr-0 pb-1 pl-1 spanNameUser">
                Administrador de la UIG
            </span>
        </div> 
    );
};

export default UserSetup; 