import React from 'react';
import UserSetup from './UserSetup';
import LogoWt from './img/LogoAlumdataWt.svg';
import '../Autosetup.css';

const TitleBar = () => {
    return (
        <div className="headerSetup w-full m-auto py-5 text-center bg-smartDark text-white font-medium text-xl">
            <UserSetup />
            <h3>Personaliza Alumdata</h3>
            <img className="logoHeaderAutosetup" src={LogoWt} width="17%"/>
        </div>
    )
}

export default TitleBar; 